<template>
    <FormWrapper>
        <PageTitle title="Update Short Leave" slot="title" link="/helpContent/Leave Approve" />
        <div v-html="userName" id="sub-heading"></div>
        <br />
        <br />
        <ValidationObserver ref="validator" >
            <FormRow>
                <div class="col-sm-2">
                    <FormSelect label="Reason Category" :items="reasonList" item-name="reason" item-value="ID" v-model="form.selectedReasonCategory" :style="{pointerEvents: display}" />
                </div>
                <div class="col-sm-2">
                    <InputDate label="Date" v-model="form.date" :style="{pointerEvents: display}" /> 
                </div>
                <div class="col-sm-2">
                    <InputText v-model="form.startTime" label="Start Time" :style="{pointerEvents: display}" />
                </div>
                <div class="col-sm-4">
                    <RadioGroup v-model="form.timeAmPm" style="margin-right: 10px;" :style="{pointerEvents: display}" >
                        <Radio label="AM">
                            AM
                        </Radio>
                        <Radio label="PM">
                            PM
                        </Radio>
                    </RadioGroup>
                </div>
            </FormRow>
            <FormRow> 
                
               
            </FormRow>
            <FormRow>
                <div class="col-sm-4">
                    <InputTextArea v-model="form.reason" label="Reason" :style="{pointerEvents: display}" />
                </div>
            </FormRow>
            <FormRow>
                <h4>Manager Comments</h4>
                <br/>
                <div class="col-sm-4">
                    <InputTextArea v-model="form.notes" label="Notes" />
                </div>
            </FormRow>
            <br>
        </ValidationObserver>
        <FormRow>
            <div class="col-sm-6">
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                    <FormButton type="success" @click="onAprroveOrReject(1)">Approve</FormButton>
                    <FormButton type="danger" @click="onAprroveOrReject(0)">Reject</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
        <!-- <FormRow>
            <div class="col-md-12">
                <h4>Leave Matrix</h4>
                <DataTable :actions="true"
                    :data="leaveMatrixData"
                    :columns="tableLeaveMatrix"
                    :is-loading="isLoading">
                </DataTable>
            </div>
        </FormRow> -->
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
            <div slot="footer">
                <FormButton @click="()=>{$router.go(-1)}">Ok</FormButton>
            </div>
        </Popup>
    </FormWrapper>
</template> 

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import loadingMixin from 'Mixins/loadingMixin';
    import {getUserNameById, leaveMatrix, leaveReason, shortLeaveList, changeLeaveStatus} from '../api';
    import InputDate from "Components/form/InputDate";
    // import tableLeaveMatrix from "./tableLeaveMatrix";
    // import DataTable from "Components/DataTable";
    import { RadioGroup, Radio } from "view-design";
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: "Update",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle,
            // DataTable,
            InputDate,
            ValidationObserver,
            RadioGroup,
            Radio,
        },
        data() {
            return {
                showMessage:{
                    isVisible: false,
                    message: ''
                },
                form:{
                    date: '',
                    startTime:'',
                    timeAmPm: '',
                    selectedReasonCategory: null,
                    reason: '',
                    notes:''
                },
                display:'none !important',
                userName: '',
                leaveTypeId: '',
                // tableLeaveMatrix,
                // leaveMatrixData: [],
                reasonList: []
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getAllList(),
            this.getLeaveRecord(),
            this.getDataByFilter()
        },
        methods: {
            getDataByFilter() {
                if(this.$route.params.id){
                    setTimeout(() => {
                        this.showLoader();
                        const filters = {};
                        filters.staffId = atob(this.$route.params.staffId);
                        filters.id = atob(this.$route.params.id);
                        const data = {
                            filterjson: {
                                filter: [filters]
                            }
                        };
                        shortLeaveList(data)
                            .then(this.handleResponse)
                            .catch(this.handleError)
                    },
                    50
                    )
                }
            },
            onAprroveOrReject(val){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                            staffId: this.$route.params.staffId?atob(this.$route.params.staffId):0,
                            staffLeaveId: this.$route.params.id?atob(this.$route.params.id):0,
                            toDoId: this.$route.params.toDoId?atob(this.$route.params.toDoId):0,
                            approvalStatusId: (val===1)?86:88, //86 -> Approved & 88 -> Reject
                            approvalComment: this.form.notes,
                            leaveTypeId: this.leaveTypeId
                        };
                        changeLeaveStatus(data).then((res)=>{
                            this.form = {};
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        })
                    }
                })
            },
            handleResponse(response) {
                this.hideLoader();
                this.form.date = response.data[0].LeaveDateStart;
                const timeArray = response.data[0].SLStartTime.split(" ");
                this.form.startTime = timeArray[0];
                this.form.timeAmPm = timeArray[1];
                this.form.selectedReasonCategory = response.data[0].LeaveReason_id;
                this.form.reason = response.data[0].Reason;
                this.leaveTypeId = response.data[0].LeaveType_id;
            },
            getLeaveRecord() {
                if (this.$route.params.staffId) {
                    let data = {
                        staff_id: atob(this.$route.params.staffId),
                    };
                    // leaveMatrix(data).then((res) => {
                    //     this.leaveMatrixData = res.data;
                    // });
                }
            },
            async getAllList() {
                leaveReason().then((res) => {
                    this.reasonList = res.data;
                });
                const filters = {};
                filters.userId = atob(this.$route.params.staffId);
                const data = {
                    filterjson: {
                        filter: [filters]
                    }
                };
                await getUserNameById(data).then((res)=>{
                    this.userName = res.data[0].name;
                });
            },
        }
    } 
</script>
<style lang="scss">
    #sub-heading{
        font-weight: 900;
        float: right;
    }

    body{
        padding-right: 0px !important;
        overflow: auto !important;
    }
    textarea{
        height: 110px !important;
    }
</style>